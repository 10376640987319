import Logger from './utils/logger';
import { withContext } from './universalComponent';
import eventBus from './utils/eventBus';
import { utilsFunctions } from './utils/setup';
import { InvalidAppIdError, SdkNotLoadedError } from './errors';

let _isSDKLoaded = false;
function isSDKLoaded() {
  return _isSDKLoaded;
}

function setSDKLoaded() {
  _isSDKLoaded = true;
}

let installedAppIds = null;
function getInstalledAppIds() {
  return installedAppIds;
}
function setInstalledAppIds(appExtensionSdkData) {
  if (!installedAppIds) {
    installedAppIds = appExtensionSdkData?.appExtensionData?.appIds || [];
  }
}

function validateOptions(options) {
  const installedAppIds = getInstalledAppIds();
  if (!installedAppIds) {
    throw new SdkNotLoadedError();
  }
  if (!installedAppIds.includes(options?.appId)) {
    throw new InvalidAppIdError();
  }
}

function init(options) {
  validateOptions(options);

  const logger = new Logger(options.appId);

  const universalComponent = withContext(options);

  const utils = Object.entries(utilsFunctions).reduce(
    (acc, [funcName, func]) => {
      acc[funcName] = async (...params) => {
        try {
          return await func(options, ...params);
        } catch (err) {
          logger.log(`Err from ${funcName}:`, err);
          throw err;
        }
      };
      return acc;
    },
    {},
  );

  // Should not expose emit functions to public
  const events = {
    addEventListener: eventBus.addEventListener,
    removeEventListener: eventBus.removeEventListener,
  };

  return {
    universalComponent,
    utils,
    events,
    logger,
  };
}

export {
  init,
  isSDKLoaded,
  setSDKLoaded,
  getInstalledAppIds,
  setInstalledAppIds,
};
