import { _initializeUniversalComponent } from './universalComponent';
import { init, isSDKLoaded, setSDKLoaded, setInstalledAppIds } from './sdk';
import { _initializeUtils } from './utils/setup';
import { getCurrentPageIdentifier } from './helpers/getData';

function _init() {
  const angularInjector = angular.element('html').injector();
  const appExtensionSdkData = angularInjector.get('appExtensionSdkData');
  const currentPage = getCurrentPageIdentifier(appExtensionSdkData);
  setInstalledAppIds(appExtensionSdkData);

  try {
    _initializeUniversalComponent(currentPage);
    _initializeUtils(currentPage);

    setSDKLoaded();
    const onLoadEvent = new CustomEvent('onAppExtensionSDKLoaded');
    window.dispatchEvent(onLoadEvent);
  } catch (err) {
    console.error('Failed to init app extension sdk', err);
  }
}

// Start initialization when angular is loaded
window.APP_EXTENSION_SDK_ANGULAR_JS_LOADED.then(() => _init());

window.shopline = Object.assign(window.shopline || {}, {
  appExtension: {
    init,
    isSDKLoaded,
  },
});
